
body{
    .mat-mdc-tab-group{
        .mat-mdc-tab-header{
            .mat-mdc-tab-labels{
                &::after{
                    content: '';
                    border-bottom: 1px solid #E6E6E6;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    position: absolute;
                }
                .mdc-tab{
                    flex-grow: unset; 
                    .mdc-tab__ripple{
                        display: none;
                    }
                    .mdc-tab__text-label{
                        font-size: 15px;
                        font-weight: 600;
                        color: var(--title_secondary);
                    }
                    &.mdc-tab--active , &:hover{
                        .mdc-tab__text-label{
                            color: var(--heraBlueColor);                            
                        }
                        .mdc-tab-indicator__content{
                            border-color: var(--heraBlueColor);
                            opacity: 1;
                        }
                    }                                        
                }
            }
        }
    }
}

.accounting_detail{
    .user_detail{
        .user_payment_block{
            &.paid {
                background: #E5F8F5;
                h5{
                    color: #00B69B;
                }
            }
            &.trial{
                background: #FCF8EA;
                h5{
                    color: #E1B92B;
                }
            }
            &.expired{
                background: #FFF3EE;
                h5{
                    color: #FF8A54; 
                }
            }
            &.canceled{
                background: #FDECED;
                h5{
                    color: #EB454F; 
                }
            }
        }
    }
}

.opction_button{
    border: 1px solid#E6E6E6;
    background-color: #F9F9F9;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    cursor: pointer;
    i{
        font-size: 21px;
        color: var(--title_secondary);
    }
}

.onboarding-table{
    table.table {
        border: 1px solid #E6E6E6;
        width: 100%;
        text-align: left;
        border-radius: 5px;
        thead{
            tr{
                padding: 15px 15px;
                display: block;
            }
        }
        tbody{
            tr {
                padding: 15px 30px;
                display: block;
                border-top: 1px solid #E6E6E6;
                &:last-child{
                    border-bottom: 1px solid #E6E6E6;
                }
                td{
                    width: 100%;
                    display: block;
                    .clients-box {
                        display: flex;
                        align-items: center;
                        width: 100%;
                    }
                    .client_total {
                        min-width: 126px;
                        .file_list {
                            min-width: 45px;
                        }
                        .clients-percentage{
                            padding: 4px 12px;
                            border-radius: 5px;
                        }
                        .success{
                            background: #E5F8F5;
                            color: #00B69B;
                        }
                        .danger{
                            background-color: #FDEBF3;
                            color: #EF3D90;
                        }
                    }
                }
            }
        }
    }
}
